@keyframes moveup {
  0% {
    opacity: 0;
    transform: translateY(12px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes movedown {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-12px);
  }
}

.odometerStart {
  display: inline-block;
  animation-name: modeup 0.25s;
  -webkit-animation: moveup 0.25s;
  animation-fill-mode: forwards;
  color: white;
}
.odometerEnd {
  display: inline-block;
  animation-name: movedown 0.25s;
  -webkit-animation: movedown 0.25s;
  animation-fill-mode: forwards;
  color: white;
}

.odometer-block {
  display: block;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Insanity';
  font-size: 92px;
  text-align: center;
  width: '100%';
}

@media only screen and (max-width: 1000px) {
  .odometer-block {
    font-size: 32px;
  }
}

@font-face {
  font-family: Insanity;
  src: url('../assets/fonts/INSANITY.ttf');
}
