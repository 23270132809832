@font-face {
  font-family: Cooper;
  src: url('./assets/fonts/COOPBL.ttf');
}

@font-face {
  font-family: Insanity;
  src: url('./assets/fonts/INSANITY.ttf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #040707;
  height: 100vh;
}

.header {
  background-image: url('./assets/CanPanion-1920x1080_16_9.jpg');
  min-height: 800px;
  background-size: cover;
}

.can {
  top: -160px;
  position: relative;
  width: 100%;
}

.about {
  margin-top: -200px;
}

@media only screen and (min-width: 600px) {
  .header {
    background-image: url('./assets/CanPanion-1920x1080_16_9.jpg');
    min-height: 800px;
    background-size: cover;
    background-position: center;
  }
}
/*
@media only screen and (min-width: 900px) {
  .header {
    background-image: url('./assets/CanPanion-1920x1080_16_9.jpg');
    min-height: 800px;
    background-size: cover;
  }
} */

@media only screen and (max-width: 860px) {
  .can {
    top: -60px;
  }
  .header {
    background-image: url('./assets/CanPanion-1920x1080_16_9.jpg');
    min-height: 250px;
    background-size: cover;
    background-position: center;
  }
  .about {
    margin-top: 0px;
  }
}

p {
  font-family: Cooper;
  font-size: 22px;
  padding-left: 20px;
  padding-right: 20px;
}
